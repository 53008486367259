<template>
    <v-app-bar location="bottom" color="white" flat class="bottom-bar">
        <v-row justify="space-between">
            <v-btn
                class="button-bg-secondary flip-icon"
                prependIcon="mdi-phone-forward"
                :text="$t('calls.lastCall')"
                :disabled="!canGoToLastCall"
                @click="goToLastCall"
            />
            <v-btn
                class="button-bg-white"
                :text="$t('calls.backToMenu')"
                @click="$router.push({ name: 'calls' })"
            />
            <v-btn
                class="button-bg-secondary"
                prependIcon="mdi-phone-forward"
                :text="$t('calls.nextCall')"
                :disabled="!canGoToNextCall"
                @click="goToNextCall"
            />
        </v-row>
    </v-app-bar>
</template>

<script setup lang="ts">
import router from "@/router";
import { store } from "@/store";
import { computed, onMounted, onUnmounted } from "vue";

const emit = defineEmits(["update"]);

const canGoToNextCall = computed(() => store.getters.canGoToNextCall);
const canGoToLastCall = computed(() => store.getters.canGoToLastCall);

function goToNextCall() {
    store.commit("incrementCurrentProspectIndex");
    const nextProspectId =
        store.state.prospectIds[store.state.currentProspectIndex].prospectId;
    emit("update", "slide-right");
    router.replace({ name: "callInterface", params: { id: nextProspectId } });
}

function goToLastCall() {
    store.commit("decrementCurrentProspectIndex");
    const lastProspectId =
        store.state.prospectIds[store.state.currentProspectIndex].prospectId;
    emit("update", "slide-left");
    router.replace({ name: "callInterface", params: { id: lastProspectId } });
}

function handleKeydown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (
        (target.tagName === "INPUT" && !target.readOnly) ||
        (target.tagName === "TEXTAREA" && !target.readOnly)
    ) {
        return;
    }

    switch (event.key) {
        case "ArrowRight":
            if (canGoToNextCall.value) {
                goToNextCall();
            }
            break;
        case "ArrowLeft":
            if (canGoToLastCall.value) {
                goToLastCall();
            }
            break;
    }
}

onMounted(() => {
    window.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleKeydown);
    store.commit("clearProspectIds");
    store.commit("clearProspectIndex");
});
</script>

<style scoped>
.v-row {
    padding: 0 3vw;
}

.bottom-bar {
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
    box-shadow: 0 1px 4px -1px rgb(var(--v-theme-text)) !important;
}
</style>
