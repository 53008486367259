<template>
    <Dialog
        v-model="dialogOpen"
        :title="title"
        width="35%"
        :isActions="false"
        :closable="false"
        :clickOutside="false"
    >
        <template #text>
            <v-row class="align-center pb-5">
                <Span class="bold">{{ $t("common.doNotLeaveThePage") }}</Span>
            </v-row>
            <v-row class="align-center">
                <v-progress-circular
                    v-model="loadingProgress"
                    color="rgb(var(--v-theme-secondary))"
                    size="64"
                    >{{ loadingProgress + "%" }}</v-progress-circular
                >
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { Dialog } from "@/components/customVuetify/index";
import { ref, watch } from "vue";
import { Span } from "./customVuetify";

const props = defineProps({
    title: String,
    progress: Number,
});

const dialogOpen = ref(false);

const loadingProgress = ref(Math.floor(props.progress || 0));

watch(
    () => props.progress,
    (newValue) => {
        if (newValue !== undefined)
            loadingProgress.value = Math.floor(newValue);
    }
);
</script>

<style scoped>
.v-row {
    height: auto !important;
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    text-align: center;
}
</style>
