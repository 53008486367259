import { getCurrentUser } from "@/commons/firebase";
import { analytics } from "@/main";
import CallInterface from "@/views/Calls/CallInterface/Index.vue";
import Calls from "@/views/Calls/Index.vue";
import AllProspects from "@/views/DB/AllProspects/Index.vue";
import ChangePassword from "@/views/Login/ChangePassword.vue";
import Performances from "@/views/Reporting/Index.vue";
import { logEvent } from "firebase/analytics";
import { getAuth, getIdTokenResult } from "firebase/auth";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Buzzer from "../views/Buzzer.vue";
import ArchivedClients from "../views/Clients/Archived/Index.vue";
import Clients from "../views/Clients/Index.vue";
import DBOrder from "../views/DB/FilteredProspects/Index.vue";
import DB from "../views/DB/Index.vue";
import ExtractCalls from "../views/Extracts/Calls/Index.vue";
import ExtractMails from "../views/Extracts/Mails/Index.vue";
import ExtractMeetings from "../views/Extracts/Meetings/Index.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Home from "../views/Home/Home.vue";
import Login from "../views/Login/Login.vue";
import OrderArchived from "../views/Orders/Archived/Index.vue";
import Order from "../views/Orders/Index.vue";
import PerformancesClient from "../views/Reporting/ReportingClient/Index.vue";
import Teams from "../views/Teams/Index.vue";
import Users from "../views/Users/Index.vue";
import Forbidden from "../views/errorCodes/Forbidden.vue";
import InternalError from "../views/errorCodes/InternalError.vue";
import NotFound from "../views/errorCodes/NotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/db",
        name: "db",
        component: DB,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/db/all",
        name: "allProspects",
        component: AllProspects,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/db/order/:id/",
        name: "dbOrder",
        component: DBOrder,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clients",
        name: "clients",
        component: Clients,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clients/archived",
        name: "archivedClients",
        component: ArchivedClients,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/calls",
        name: "calls",
        component: Calls,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/calls/callinterface/:id",
        name: "callInterface",
        component: CallInterface,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/extracts/meetings",
        name: "extractMeetings",
        component: ExtractMeetings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/extracts/mails",
        name: "extractMails",
        component: ExtractMails,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/extracts/calls",
        name: "extractCalls",
        component: ExtractCalls,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/buzzer",
        name: "buzzer",
        component: Buzzer,
        meta: {
            requiresAuth: true,
            requiresTovia: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/orders",
        name: "orders",
        component: Order,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/orders/archived",
        name: "archivedOrders",
        component: OrderArchived,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/performances",
        name: "performances",
        component: Performances,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/performances/client/:id",
        name: "reportingClient",
        component: PerformancesClient,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: ChangePassword,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/forgot",
        name: "forgot",
        component: ForgotPassword,
    },
    {
        path: "/teams",
        name: "teams",
        component: Teams,
    },
    {
        path: "/403",
        name: "forbidden",
        component: Forbidden,
    },
    {
        path: "/500",
        name: "internalerror",
        component: InternalError,
    },
    {
        path: "/:catchAll(.*)",
        name: "notfound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

let pageLoadTime = performance.now();

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some(
        (record) => record.meta.requiresAdmin
    );
    const requiresTovia = to.matched.some(
        (record) => record.meta.requiresTovia
    );

    const user = await getCurrentUser();

    const auth = getAuth();

    let token;
    if (auth.currentUser) {
        token = await getIdTokenResult(auth.currentUser);
    }

    if (from.name) {
        const pageUnloadTime = performance.now();
        const timeSpent = pageUnloadTime - pageLoadTime;
        logEvent(analytics, "page_unload", {
            page_path: from.path,
            time_spent: timeSpent,
            user_id: auth.currentUser?.uid,
            timestamp: new Date().toISOString(),
        });
    }

    pageLoadTime = performance.now();
    logEvent(analytics, "page_load", {
        page_path: to.path,
        user_id: auth.currentUser?.uid,
        timestamp: new Date().toISOString(),
    });

    if (requiresAuth && !auth.currentUser) {
        next("/login");
    } else if (
        requiresAuth &&
        token?.claims.firstLogin == true &&
        to.path !== "/change-password"
    ) {
        next("/change-password");
    } else if (requiresAdmin && token?.claims.admin !== true) {
        next("/403");
    } else if (requiresTovia && user?.ClientId !== "6X8mesClIltu3m4vtIae") {
        next("/403");
    } else if (to.name == "login" && auth.currentUser) {
        next("/home");
    } else {
        next();
    }
});

export default router;
