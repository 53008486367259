<template>
    <Card :title="$t('calls.context')">
        <template #content>
            <CardTextField
                v-model="prospect.Name"
                label="prospects.company"
                :readonly="readonly"
                textArea
            />
            <CardTextField
                v-model="prospect.Phone"
                label="prospects.phone"
                :readonly="readonly"
            />
            <CardTextField
                v-model="prospect.Mail"
                label="prospects.mail"
                :readonly="readonly"
            />
            <CardTextField
                v-model="prospect.Website"
                label="prospects.website"
                :readonly="readonly"
                :link="isLink ? prospect.Website : ''"
            />
            <CardTextField
                v-model="prospect.Address"
                label="prospects.address"
                :readonly="readonly"
                textArea
            />
            <CardTextField
                v-model="prospect.City"
                label="prospects.city"
                :readonly="readonly"
                textArea
            />
            <CardTextField
                v-model="prospect.PostalCode"
                label="prospects.postalCode"
                :readonly="readonly"
            />
            <CardTextField
                v-model="prospect.Department"
                label="prospects.department"
                :readonly="readonly"
            />
            <CardTextField
                v-model="prospect.Region"
                label="prospects.region"
                :readonly="readonly"
            />
            <CardTextField
                v-model="prospect.Size"
                label="prospects.size"
                :readonly="true"
            />
            <CardTextField
                v-model="prospect.Turnover"
                label="prospects.turnover"
                :readonly="readonly"
            />
            <v-row class="divider-row">
                <v-col class="divider-col">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <Span class="db-comment-title">{{
                        $t("prospects.dbComments")
                    }}</Span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <TextArea
                        v-model="prospect.Comment"
                        :label="$t('prospects.dbComments')"
                        :disableMessage="true"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-end">
                    <v-btn
                        v-if="readonly"
                        class="button-bg-white"
                        @click="readonly = false"
                        prependIcon="mdi-pencil-outline"
                        :text="$t('common.modifyInfos')"
                    />
                    <v-btn
                        v-else
                        class="button-bg-secondary"
                        :loading="isLoading"
                        @click="updateData"
                        prependIcon="mdi-check"
                        :text="$t('common.validateModifications')"
                    />
                </v-col>
            </v-row>
        </template>
    </Card>
</template>

<script setup lang="ts">
import { formatPhoneNumber, formatPrice } from "@/commons/convertion";
import { Span } from "@/components/customVuetify";
import Card from "@/components/customVuetify/Card.vue";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { db } from "@/main";
import { Prospect } from "@/types";
import { doc, updateDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();
const route = useRoute();
const prospectId = route.params.id as string;
const readonly = ref(true);
const isLoading = ref(false);
const isLink = ref(false);

const prospect = ref<any>({
    APECode: [""],
    Address: "",
    AuthorId: "",
    City: "",
    ClientId: "",
    Comment: "",
    Department: "",
    Mail: "",
    Name: "",
    PostalCode: "",
    Phone: "",
    Region: "",
    Size: "",
    Turnover: "",
    Website: "",
});

const props = defineProps({
    prospectData: {
        type: Object as () => Prospect,
        required: true,
    },
    orderId: {
        type: String,
        required: true,
    },
});

async function updateData() {
    isLoading.value = true;
    if (!prospectId) {
        console.error("Order ID or Client ID is missing");
        return;
    }

    const docRef = doc(
        db,
        "Orders",
        props.orderId,
        "Prospects",
        prospectId.toString()
    );

    try {
        const updatedData = { ...prospect.value };
        delete updatedData.Size;

        await updateDoc(docRef, updatedData);
    } catch (error) {
        console.error("Error updating document:", error);
    } finally {
        isLoading.value = false;
        readonly.value = true;
    }
}

watch(
    () => props.prospectData,
    (newVal) => {
        if (newVal) {
            for (const key in prospect.value) {
                if (key in newVal && key in prospect.value) {
                    (prospect.value as any)[key] = (newVal as any)[key];
                }
                if (key === "Phone" && newVal[key] !== "") {
                    prospect.value.Phone = formatPhoneNumber(newVal[key]);
                }
                if (key === "Turnover" && newVal[key] !== "") {
                    prospect.value.Turnover = formatPrice(newVal[key]);
                }
                if (
                    key === "Website" &&
                    newVal[key] !== "" &&
                    newVal[key] !== "N/A" &&
                    newVal[key] !== "NA" &&
                    newVal[key] !== "NC"
                ) {
                    isLink.value = true;
                }
            }
        }
    },
    { deep: true }
);
</script>

<style scoped>
.divider-row {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.divider-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.db-comment-title {
    font-size: 1rem;
    font-weight: bold !important;
    padding-top: 1rem;
}
</style>
