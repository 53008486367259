<template>
    <Title :title="$t('reporting.details')" />
    <Filters @applyFilters="applyFilters" />
    <List :users="isLoading ? [] : filteredUsers" :loading="isLoading" />
</template>

<script setup lang="ts">
import { getActiveOrderListNameId } from "@/commons/firebase";
import {
    getCallsForOrder,
    getCallsTakenForOrder,
    getDecisionMakersForOrder,
    getRdvForOrder,
} from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { collection, getDocs, query } from "firebase/firestore";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const users = ref<any[]>([]);
const isLoading = ref(false);
const filters = ref({
    FirstName: "",
    LastName: "",
    OrderId: [],
    startDate: new Date(),
    endDate: new Date(),
});

const filteredUsers = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return users.value;
    }
    return users.value.filter((prospect: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return value.some((v) => v === prospect[key]);
            }
            if (
                typeof value === "number" &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return prospect[key] === value;
            }
            if (
                typeof value === "string" &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return prospect[key]
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return value.some(
                    (v) =>
                        (v as string).toLowerCase() ===
                        prospect[key].toLowerCase()
                );
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await setupFirestoreListener();
}

const orderIds = await getActiveOrderListNameId();

watch(
    () => store.state.currentClient,
    async (client) => {
        if (client) {
            const result = await getActiveOrderListNameId();
            orderIds.value = Array.isArray(result.value) ? result.value : [];
            setupFirestoreListener();
        }
    }
);

async function addCountsToUsersWithoutOrder() {
    users.value = await Promise.all(
        users.value.map(async (user) => {
            let totalCalls = 0;
            let totalPickedUp = 0;
            let totalDecisionMakers = 0;
            let totalRdv = 0;

            for (const orderId of orderIds.value) {
                totalCalls += await getCallsForOrder(
                    orderId.id,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalPickedUp += await getCallsTakenForOrder(
                    orderId.id,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalDecisionMakers += await getDecisionMakersForOrder(
                    orderId.id,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalRdv += await getRdvForOrder(
                    orderId.id,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
            }

            return {
                ...user,
                totalCalls: totalCalls || "0",
                totalPickedUp: totalPickedUp || "0",
                totalDecisionMakers: totalDecisionMakers || "0",
                totalRdv: totalRdv || "0",
                rateOnPickedUp: totalPickedUp
                    ? Number(((totalRdv / totalPickedUp) * 100).toFixed(2)) +
                      "%"
                    : "0%",
                rateOnDecisionMakers: totalDecisionMakers
                    ? Number(
                          ((totalRdv / totalDecisionMakers) * 100).toFixed(2)
                      ) + "%"
                    : "0%",
            };
        })
    );
    isLoading.value = false;
}

async function addCountsToUsersWithOrder() {
    users.value = await Promise.all(
        users.value.map(async (user) => {
            let totalCalls = 0;
            let totalPickedUp = 0;
            let totalDecisionMakers = 0;
            let totalRdv = 0;

            for (const orderId of filters.value.OrderId) {
                totalCalls += await getCallsForOrder(
                    orderId,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalPickedUp += await getCallsTakenForOrder(
                    orderId,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalDecisionMakers += await getDecisionMakersForOrder(
                    orderId,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
                totalRdv += await getRdvForOrder(
                    orderId,
                    filters.value.startDate,
                    filters.value.endDate,
                    user.id
                );
            }

            return {
                ...user,
                totalCalls: totalCalls || "0",
                totalPickedUp: totalPickedUp || "0",
                totalDecisionMakers: totalDecisionMakers || "0",
                totalRdv: totalRdv || "0",
                rateOnPickedUp: totalPickedUp
                    ? Number(((totalRdv / totalPickedUp) * 100).toFixed(2)) +
                      "%"
                    : "0%",
                rateOnDecisionMakers: totalDecisionMakers
                    ? Number(
                          ((totalRdv / totalDecisionMakers) * 100).toFixed(2)
                      ) + "%"
                    : "0%",
            };
        })
    );
    isLoading.value = false;
}

async function setupFirestoreListener() {
    isLoading.value = true;
    users.value = [];
    const ordersCollection = collection(db, "Users");
    const q = query(ordersCollection);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
        users.value.push({
            id: doc.id,
            FirstName: doc.data().FirstName,
            LastName: doc.data().LastName.toUpperCase(),
        });
    });
    if (filters.value.OrderId == null || filters.value.OrderId.length === 0) {
        await addCountsToUsersWithoutOrder();
    } else {
        await addCountsToUsersWithOrder();
    }
    users.value = users.value.filter((user) => user.totalCalls > 0);
}
</script>
