<template>
    <Dialog
        :title="$t('db.confirmReportSubmission')"
        width="35vw"
        @close="$emit('close')"
    >
        <template #text>
            <div style="text-align: center">
                <Span style="font-size: 1rem">
                    {{ $t("db.confirmReportDescription") }}
                </Span>
            </div>
        </template>
        <template #actions>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        @click="sendReport"
                        class="button-bg-secondary"
                        :loading="isLoading"
                        :text="$t('popupMyDayReport.confirm')"
                        prepend-icon="mdi-check"
                    />
                </v-col>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import {
    getDateObjFromDateString,
    getSizeStringFromCode,
    getTrigram,
} from "@/commons/convertion";
import {
    getCurrentClient,
    getCurrentUser,
    getOrderFromId,
    getUserFromId,
} from "@/commons/firebase";
import { getMailForDate, getRdvForDate } from "@/commons/reporting";
import { Dialog, Span } from "@/components/customVuetify";
import { db } from "@/main";
import { APECodes, rdvTypes, SectionCodes, TimeWorked } from "@/types";
import emailjs from "emailjs-com";
import * as ExcelJS from "exceljs";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { computed, onMounted, ref, toRaw } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const isLoading = ref(false);
const currentUser = await getCurrentUser();
const meetings = ref<any>([]);
const mails = ref<any>([]);

const props = defineProps({
    dataToSubmit: {
        type: Array,
        default: () => [],
    },
    isHalfDay: {
        type: Boolean,
        default: false,
    },
});

const filters = ref({
    startDate: new Date(),
    endDate: new Date(),
});

const filters2 = ref({
    startDate: new Date("1970-01-01T00:00:00Z"),
    endDate: new Date(),
    archived: true,
});

const filteredMeetings = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return meetings.value;
    }
    return meetings.value.filter(() =>
        Object.entries(filters.value).every(([key]) => {
            if (key === "id") return true;
            return true;
        })
    );
});

const filteredMails = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return mails.value;
    }
    return mails.value.filter(() =>
        Object.entries(filters.value).every(([key]) => {
            if (key === "id") return true;
            return true;
        })
    );
});

const emit = defineEmits(["close", "success"]);

async function getCompanyDataFromFirebase(orderId: string, prospectId: string) {
    let companyInfoRef = doc(db, "Orders", orderId, "Prospects", prospectId);
    let companyInfo = await getDoc(companyInfoRef);
    let companyData = companyInfo.data();
    if (!companyData) {
        companyInfoRef = doc(
            db,
            "ArchivedOrders",
            orderId,
            "ArchivedProspects",
            prospectId
        );
        companyInfo = await getDoc(companyInfoRef);
        companyData = companyInfo.data();
    }
    return companyData;
}

function generateProspectDetails(prospects: any) {
    return prospects
        .map(
            (prospect: any) => `
        <p><strong>${prospect.name}</strong></p>
        <ul>
            <li><strong style="font-weight: bold;">Nombre de rdv décroché : ${prospect.totalRdv} </strong></li>
            <li><strong style="font-weight: bold;">Actions menées : </strong>${prospect.reportComment}</li>
        </ul>
    `
        )
        .join("");
}

function creationTab(prospects: any) {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    const tableHTML = `
    <table class="email-table">
        <thead>
            <tr>
                <th style="background-color: #58698c; color: white;" colspan="${
                    prospects.length + 1
                }">Typologies de cibles</th>
            </tr>
            <tr>
                <th class="typo-normal" style="text-align: center;">${formattedDate}</th>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<th style="text-align: center;">${prospect.name}</th>`
                    )
                    .join("")}
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="typo-normal" style="text-align: center;">Calls effectués</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.totalCalls}</td>`
                    )
                    .join("")}
            </tr>
            <tr>
                <td class="typo-normal" style="text-align: center;">Calls décroché</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.totalCallsTaken}</td>`
                    )
                    .join("")}
            </tr>
            <tr>
                <td class="typo-normal" style="text-align: center;">Nombre de décideurs</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.totalDecisionMakers}</td>`
                    )
                    .join("")}
            </tr>
            <tr>
                <td class="typo-normal" style="text-align: center;">Rendez-vous pris</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.totalRdv}</td>`
                    )
                    .join("")}
            </tr>
            <tr class="highlight">
                <td style="text-align: center;">Taux sur décroché</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.rdvOverCallsTaken}</td>`
                    )
                    .join("")}
            </tr>
            <tr class="highlight">
                <td style="text-align: center;">Taux sur décideurs</td>
                ${prospects
                    .map(
                        (prospect: any) =>
                            `<td style="text-align: center;">${prospect.rdvOverDecisionMakers}</td>`
                    )
                    .join("")}
            </tr>
        </tbody>
    </table>`;
    return tableHTML;
}

// function textExtractMeeting() {
//     if (props.dataToSubmit.length > 0) {
//         return "Vous trouverez en PJ un fichier d'extraction de rendez-vous.\nCelui-ci reprendra l'intégralité des informations sur les entreprises et propects ayant acceptés un échange.";
//     }
//     return "";
// }

// function textExtractMail() {
//     if (mails.value.length > 0) {
//         return "\nVous trouverez un second onglet reprenant les prospects demandant un premier contact par mail";
//     }
//     return "";
// }

async function getDataFromFirestoreMeeting() {
    const rdvList = await getRdvForDate(
        getCurrentClient().value.id,
        filters2.value.startDate,
        filters2.value.endDate,
        filters2.value.archived
    );

    meetings.value = await Promise.all(
        rdvList.map(async (rdv: any) => {
            const user = (await getUserFromId(rdv.EmployeeId)).data();
            let date;
            let hours;
            let minutes;
            if (rdv.AppointmentDate) {
                date = rdv.AppointmentDate.toDate();
                hours = date.getHours().toString().padStart(2, "0");
                minutes = date.getMinutes().toString().padStart(2, "0");
            }
            const order = (await getOrderFromId(rdv.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                rdv.OrderId,
                "Prospects",
                rdv.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    rdv.OrderId,
                    "ArchivedProspects",
                    rdv.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            return {
                Type: rdvTypes[rdv.RdvType as keyof typeof rdvTypes],
                Associate: rdv.AssociateId,
                Company: prospectData?.Name,
                Contact: rdv.FirstName + " " + rdv.LastName.toUpperCase(),
                FirstName: rdv.FirstName,
                LastName: rdv.LastName,
                Position: rdv.Position,
                Phone: rdv.Phone,
                Mail: rdv.Mail,
                OrderId: rdv.OrderId,
                ProspectId: rdv.ProspectId,
                Order: order?.Name,
                Comments: rdv.Comments,
                Date: date ? date.toLocaleDateString("fr-FR") : "",
                Hour: hours ? hours + ":" + minutes : "",
                DateTaken: rdv.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                Salesman: rdv.Salesman,
                City: prospectData?.City,
            };
        })
    );
}

async function getDataFromFirestoreMail() {
    const mailList = await getMailForDate(
        getCurrentClient().value.id,
        filters2.value.startDate,
        filters2.value.endDate,
        filters2.value.archived
    );

    mails.value = await Promise.all(
        mailList.map(async (mail: any) => {
            const user = (await getUserFromId(mail.EmployeeId)).data();
            const order = (await getOrderFromId(mail.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                mail.OrderId,
                "Prospects",
                mail.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    mail.OrderId,
                    "ArchivedProspects",
                    mail.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            return {
                Company: prospectData?.Name,
                Contact: mail.FirstName + " " + mail.LastName.toUpperCase(),
                FirstName: mail.FirstName,
                LastName: mail.LastName,
                Position: mail.Position,
                Phone: mail.Phone,
                Mail: mail.Mail,
                Order: order?.Name,
                OrderId: mail.OrderId,
                DocId: mail.DocId,
                ProspectId: mail.ProspectId,
                Comments: mail.Comments,
                CallDate: mail.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
            };
        })
    );
}

function concatWithCommas(strings: string[]): string {
    if (strings.length === 0) {
        return "";
    }
    if (strings.length === 1) {
        return strings[0];
    }
    return strings.join(", ");
}

async function fetchAndProcessDataMeeting() {
    try {
        const response = await fetch("/excels/TemplateExtractionMeeting.xlsx");
        if (!response.ok) {
            throw new Error("Erreur lors du chargement du fichier template.");
        }
        const arrayBuffer = await response.arrayBuffer();

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);

        let worksheet = workbook.getWorksheet(1);

        if (!worksheet) {
            throw new Error("La feuille de calcul est introuvable.");
        }

        const sortedMeetings = filteredMeetings.value
            .slice()
            .sort((a: any, b: any) => {
                const dateA = getDateObjFromDateString(a.DateTaken);
                const dateB = getDateObjFromDateString(b.DateTaken);
                return dateA.getTime() - dateB.getTime();
            });

        const rowPromises = sortedMeetings.map(
            async (item: any, index: any) => {
                const companyData = await getCompanyDataFromFirebase(
                    item.OrderId,
                    item.ProspectId
                );
                let APECodeDetail = (await getOrderFromId(item.OrderId)).data();

                const sectionCode = APECodeDetail.Sections || "";
                const sectionDescription =
                    SectionCodes[sectionCode as keyof typeof SectionCodes] ||
                    "";
                const codeApes = APECodeDetail.ApeCodes || "";
                const codeApes2 = concatWithCommas(codeApes);
                const classCode = APECodeDetail.ApeCodes || "";
                const classDescription =
                    APECodes[classCode as keyof typeof APECodes] || "";

                const row = worksheet.getRow(index + 3);
                row.getCell(1).value = index + 1;
                row.getCell(2).value = item.DateTaken || "";
                row.getCell(3).value = item.Type || "";
                row.getCell(4).value = item.Salesman || "";
                row.getCell(5).value = item.Company || "";
                row.getCell(6).value = sectionDescription || "";
                row.getCell(7).value = codeApes2 || "";
                row.getCell(8).value = classDescription || "";
                row.getCell(9).value = companyData?.Turnover || "";
                row.getCell(10).value =
                    getSizeStringFromCode(companyData?.Size) || "";
                row.getCell(11).value = companyData?.Address || "";
                row.getCell(12).value = companyData?.City || "";
                row.getCell(13).value = companyData?.PostalCode || "";
                row.getCell(14).value = companyData?.Phone || "";
                row.getCell(15).value = companyData?.Website || "";
                if (item.DateToBeDefined === true) {
                    row.getCell(16).value = "A définir";
                } else {
                    row.getCell(16).value = item.Date || "";
                }
                if (item.DateToBeDefined === true) {
                    row.getCell(17).value = "A définir";
                } else {
                    row.getCell(17).value = item.Hour || "";
                }
                row.getCell(18).value = item.LastName || "";
                row.getCell(19).value = item.FirstName || "";
                row.getCell(20).value = item.Position || "";
                row.getCell(21).value = item.Phone || "";
                row.getCell(22).value = item.Mail || "";
                row.getCell(23).value = item.Comments || "";

                if (
                    item.DateTaken !==
                    filters.value.endDate.toLocaleDateString("fr-FR")
                ) {
                    row.getCell(1).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FFE6E6E6" },
                    };
                }
                row.commit();
            }
        );

        await Promise.all(rowPromises);

        const buffer = await workbook.xlsx.writeBuffer();
        const base64 = arrayBufferToBase64(buffer);
        return base64;
    } catch (error) {
        console.error("Error processing Excel file:", error);
        alert(
            "Une erreur est survenue lors du traitement du fichier Excel : " +
                (error as Error).message
        );
        return null;
    }
}

async function fetchAndProcessDataMail() {
    try {
        const response = await fetch("/excels/TemplateExtractionMail.xlsx");
        if (!response.ok) {
            throw new Error("Erreur lors du chargement du fichier template.");
        }
        const arrayBuffer = await response.arrayBuffer();

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);

        let worksheet = workbook.getWorksheet(1);

        if (!worksheet) {
            throw new Error("La feuille de calcul est introuvable.");
        }
        const sortedMails = filteredMails.value
            .slice()
            .sort((a: any, b: any) => {
                const dateA = getDateObjFromDateString(a.CallDate);
                const dateB = getDateObjFromDateString(b.CallDate);
                return (dateA as any) - (dateB as any);
            });

        const rowPromises = sortedMails.map(async (item: any, index: any) => {
            const companyData = await getCompanyDataFromFirebase(
                item.OrderId,
                item.ProspectId
            );
            let APECodeDetail = (await getOrderFromId(item.OrderId)).data();

            const sectionCode = APECodeDetail.Sections || "";
            const sectionDescription =
                SectionCodes[sectionCode as keyof typeof SectionCodes] || "";
            const codeApes = APECodeDetail.ApeCodes || "";
            const codeApes2 = concatWithCommas(codeApes);
            const classCode = APECodeDetail.ApeCodes || "";
            const classDescription =
                APECodes[classCode as keyof typeof APECodes] || "";

            const row = worksheet.getRow(index + 3);
            row.getCell(1).value = index + 1;
            row.getCell(2).value = item.CallDate || "";
            row.getCell(3).value = item.Company || "";
            row.getCell(4).value = sectionDescription || "";
            row.getCell(5).value = codeApes2 || "";
            row.getCell(6).value = classDescription || "";
            row.getCell(7).value = companyData?.Turnover || "";
            row.getCell(8).value =
                getSizeStringFromCode(companyData?.Size) || "";
            row.getCell(9).value = companyData?.Address || "";
            row.getCell(10).value = companyData?.City || "";
            row.getCell(11).value = companyData?.PostalCode || "";
            row.getCell(12).value = companyData?.Phone || "";
            row.getCell(13).value = companyData?.Website || "";
            row.getCell(14).value = item.LastName || "";
            row.getCell(15).value = item.FirstName || "";
            row.getCell(16).value = item.Position || "";
            row.getCell(17).value = item.Phone || "";
            row.getCell(18).value = item.Mail || "";
            row.getCell(19).value = item.Comments || "";
            row.commit();

            if (
                item.CallDate !==
                filters.value.endDate.toLocaleDateString("fr-FR")
            ) {
                row.getCell(1).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFE6E6E6" },
                };
            }
            row.commit();
        });

        await Promise.all(rowPromises);

        const buffer = await workbook.xlsx.writeBuffer();
        const base64 = arrayBufferToBase64(buffer);
        return base64;
    } catch (error) {
        console.error("Error processing Excel file:", error);
        alert(
            "Une erreur est survenue lors du traitement du fichier Excel : " +
                (error as Error).message
        );
        return null;
    }
}

function arrayBufferToBase64(buffer: any) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function adaptSizeTable(size: number) {
    if (size === 1) {
        return "50";
    } else if (size === 2) {
        return "60";
    } else if (size === 3) {
        return "70";
    } else if (size === 4) {
        return "80";
    } else if (size === 5) {
        return "90";
    } else {
        return "100";
    }
}

// function textHalfOrAllDay() {
//     if (props.isHalfDay) {
//         return "demi-journée";
//     }
//     return "journée";
// }

async function sendEmail() {
    const excelBase64Meeting = await fetchAndProcessDataMeeting();
    if (!excelBase64Meeting) {
        isLoading.value = false;
        return;
    }
    const excelBase64Mail = await fetchAndProcessDataMail();
    if (!excelBase64Mail) {
        isLoading.value = false;
        return;
    }

    const data = toRaw(props.dataToSubmit);

    const emailContent = generateProspectDetails(data);
    const codeTab = creationTab(data);
    const htmlEmailContent = `<p>${emailContent}</p>`;
    const tab = `<p>${codeTab}</p>`;
    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(
        today.getMonth() + 1
    ).padStart(2, "0")}${String(today.getDate()).padStart(2, "0")}`;

    const templateParams = {
        size_table: adaptSizeTable(data.length),
        actionsDetails: htmlEmailContent,
        // halfOrAllDay: textHalfOrAllDay(),
        tab: tab,
        // textExtractMeeting: textExtractMeeting(),
        // textExtractMail: textExtractMail(),
        senderName: currentUser.FirstName + " " + currentUser.LastName,
        date: formattedDate,
        clientName: getCurrentClient().value.name,
        attachment: excelBase64Meeting,
        attachment_filename: "ExtractionRDV.xlsx",
        attachment2: excelBase64Mail,
        attachment2_filename: "ExtractionMail.xlsx",
    };

    const serviceId = process.env.VUE_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.VUE_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.VUE_APP_EMAILJS_USER_ID;

    if (!serviceId || !templateId || !userId) {
        console.error("Missing emailjs configuration");
        isLoading.value = false;
        return;
    }

    emailjs.send(serviceId, templateId, templateParams, userId);
}

async function createStatDoc() {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}-${String(
        today.getMonth() + 1
    ).padStart(2, "0")}-${today.getFullYear()}`;

    const statDocRef = doc(db, "Stats", formattedDate);

    for (const item of props.dataToSubmit as any) {
        const timeWorked = props.isHalfDay
            ? TimeWorked.halfDay
            : TimeWorked.fullDay;
        const orderData = {
            Date: new Date(),
            EmployeeId: currentUser.FirebaseUID,
            ClientId: getCurrentClient().value.id,
            TimeWorked: timeWorked,
            OrderId: item.id,
            ReportComment: item.reportComment,
        };
        await addDoc(collection(statDocRef, "Details"), orderData);
    }
}

async function sendReport() {
    isLoading.value = true;
    await createStatDoc();
    await sendEmail();
    emit("success");
    emit("close");
    isLoading.value = false;
}

onMounted(() => {
    getDataFromFirestoreMeeting();
    getDataFromFirestoreMail();
});
</script>
