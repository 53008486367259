import { Salutations } from "./call";
import { FirestoreDoc } from "./common";

export interface Contact extends FirestoreDoc {
    Mail: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Position: string;
    Salutation: Salutations;
}

export interface Prospect {
    Address: string;
    APECode: string[];
    AuthorId: string;
    Called: boolean;
    City: string;
    ClientId: string;
    Comment: string;
    Mail: string;
    Name: string;
    Phone: string;
    PostalCode: string;
    Region: string;
    Size: string;
    Turnover: string;
    Website: string;
}

export const defaultProspect: Prospect = {
    APECode: [] as string[],
    Address: "",
    AuthorId: "",
    Called: false,
    City: "",
    ClientId: "",
    Comment: "",
    Mail: "",
    Name: "",
    PostalCode: "",
    Phone: "",
    Region: "",
    Size: null as unknown as string,
    Turnover: "",
    Website: "",
};
